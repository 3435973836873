import axios from 'axios';

const apiURL = '/api/eventual/';

async function ListarPesquisa(search) {
    try {
        console.log("Pesquisa: ",search);
        const response = await axios.get(apiURL+"listarPesquisa", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            params: {
                search: search
            }
        });
        
        if (response.status !== 200) {
            throw new Error(response.data);
        }
        return response;
    } catch (error) {
        console.error('Erro:', error);
        return false;
    }
}

async function ListarDados(type, value) {
    try {
        console.log(value);

        console.log("before response colaborador");
        const response = await axios.get(apiURL+"buscarDados", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            params: {
                value: value,
                type: type
            }
        });

        console.log("Colaborador Response: ", response);
        if (response.status !== 200) {
            throw new Error(response.data);
        }
        return response;
    } catch (error) {
        console.error('Erro:', error);
        return false;
    }
}

async function ListarHistoricoEventual(callback) {
    try {

        console.log("before response historico Colaborador");
        const response = await axios.get(apiURL+"historicoEventual", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });

        console.log("Colaborador Response: ", response);
        if (response.status !== 200) {
            throw new Error(response.data);
        }
        callback(null, response);
    } catch (error) {
        console.error('Erro:', error);
       
    }
}

async function BuscarDocumento(id) {
    try {
        console.log(id);

        console.log("before response documento");
        const response = await axios.get(apiURL+"buscarDocumento", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            params: {
                id: id
            }
        });

        if (response.status !== 200) {
            throw new Error(response.data);
        }

        return response;
    } catch (error) {
        console.error('Erro:', error);
        return false;
    }
}

async function CriarEventual(formData){

    console.log(formData);

    try {
        console.log(formData);

        console.log("before response formData");
        const response = await axios.post(apiURL+"criarEventual", formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });

        return response;
    } catch (error) {
        console.error('Erro:', error);
        return error.response.data;
    }

}

export { ListarPesquisa, CriarEventual, ListarDados, BuscarDocumento, ListarHistoricoEventual };


