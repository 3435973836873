import './eventual.css';
import search from "../../../img/search.svg";
import historicoStyle from "./HistoricoEventual.module.css";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LocationInput from '../../../components/LocationInput/location-input.jsx';
import Container from '../../../components/Container/index.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CriarEventual, BuscarDocumento, ListarDados, ListarPesquisa } from '../../../services/api-eventual.js';
import style from './CustomDatePicker.css';
import { useSnackbar } from 'notistack';
import InputMask from 'react-input-mask';
import AsyncSelect from 'react-select/async';


const Eventual = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [cpfEncontrado, setCpfEncontrado] = useState("");
    const [numDoc, setNumDoc] = useState("");
    const [tipoDoc, setTipoDoc] = useState("");
    const [loading, setLoading] = useState(false);
    const [colaborador, setColaborador] = useState("");
    const [cep, setCep] = useState("");
    const [dataExpedicao, setDataExpedicao] = useState(null);
    const [dataNascimento, setNascimento] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [formData, setFormData] = useState({
        nome: '',
        cpf: '',
        nomePai: '',
        nomeMae: '',
        tipoDoc: '',
        numDoc: '',
        diaExpedicao: '',
        mesExpedicao: '',
        anoExpedicao: '',
        diaNascimento: '',
        mesNascimento: '',
        anoNascimento: '',
        orgaoEmissor: '',
        codUfOrgaoEmissor: '',
        ufOrgaoEmissor: '',
        codLocalNascimento: '',
        localNascimento: '',
        codUfNascimento: '',
        ufNascimento: '',
        ctpsNumero: '',
        ctpsSerie: '',
        cep: '',
        bairro: '',
        complemento: '',
        codCidade: '',
        cidade: '',
        endereco: '',
        codUfEndereco: '',
        ufEndereco: '',
        telefone: '',
        profissao: '',
        empresa: '',
        localDestino: '',
        motivacao: '',
        gates: '',
        gates2: '',
        numero: '',
        diaInicio:'',
        mesInicio:'',
        anoInicio:'',
        diaFim:'',
        mesFim:'',
        anoFim:'',
    });

    const [listaColaborador, setListaColaborador] = useState(['']);

    const [options, setOptions] = useState({
        mask: "xxxxxxxx",
        replacement: { 9: /[0-9]/, a:/[a-zA-Z]/, x:/[a-zA-Z0-9]/ },
    });

    const showAlert = (message, variant) => {
        enqueueSnackbar(message, { variant });
    };
    const cpfMask = (value) => {
        return value
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada    
    };

    const handleExpDate = (date) => {
        setDataExpedicao(date);
        const selectedDay = date.getDate();
        const selectedMonth = date.getMonth() + 1;
        const selectedYear = date.getFullYear();
        setFormData({ ...formData, 
            diaExpedicao: selectedDay,
            mesExpedicao: selectedMonth,
            anoExpedicao: selectedYear
        });
    };

    const handleNascDate = (date) => {
        setNascimento(date);
        const selectedDay = date.getDate();
        const selectedMonth = date.getMonth() + 1;
        const selectedYear = date.getFullYear();
        setFormData({ ...formData, 
            diaNascimento: selectedDay,
            mesNascimento: selectedMonth,
            anoNascimento: selectedYear
        });
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        if (start && end) {
            // Calcular a diferença entre as datas
            const diffInDays = (end - start) / (1000 * 60 * 60 * 24);
            if (diffInDays > 10) {
                showAlert('O período deve ser de no máximo 10 dias.', 'error');
                return; // Não atualiza as datas se o intervalo for maior que 10 dias
            }
        }
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            setFormData({ ...formData,
                diaInicio: start.getDate(),
                mesInicio: start.getMonth() + 1,
                anoInicio: start.getFullYear(),
                diaFim: end.getDate(),
                mesFim: end.getMonth() + 1,
                anoFim: end.getFullYear(),
            });
            setShowDatePicker(false);
        }
    };

    const handleStateChange = async (event) => {
        const stateId = event.target.selectedIndex;
        const stateName = event.target.value;
        setFormData({ ...formData,
                        codUfOrgaoEmissor: stateId, 
                        ufOrgaoEmissor: stateName });
    };

    const handleCPF = (event) => {
        const cpfValue = cpfMask(event.target.value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            cpf: cpfValue.replace(/\D/g, ''),
            ctpsNumero: cpfValue.replace(/\D/g, '').slice(0, 7),
            ctpsSerie: cpfValue.replace(/\D/g, '').slice(-4)
        }));
    };


    const buscarEnderecoPorCEP = async (cep) => {
        try {
            const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
            const data = await response.json();
            if (data.erro) {
                throw new Error('CEP não encontrado');
            }
            return data;
        } catch (error) {
            console.error('Erro ao buscar o endereço:', error);
            return null;
        }
    };

    const cepMask = (value) => {
        return value
            .replace(/\D/g, '') // Remove caracteres não numéricos
            .replace(/(\d{5})(\d)/, '$1-$2'); // Adiciona hífen após os 5 primeiros dígitos
    };

    const handleInputChange = async (event) => {
        if(event == null) return;
        const { name, value } = event.target ? event.target : "";
        let newValue = value;
    
        switch (name) {
            case 'cep':
                newValue = value.replace(/[^0-9]/g, '');
                setCep(value);
                if (newValue.length === 8){
                    setFormData({...formData,
                                cep:newValue
                    });
                }else{
                    setFormData({...formData,
                        cep:""
                    });
                }
                break;
            case "tipoDoc": 
                setFormData({...formData,
                            tipoDoc:value.replace(/[^a-zA-Z0-9]/g, ''),
                });
                setTipoDoc(value);
                break;
            case "numDoc":
                setFormData({...formData,
                            numDoc:value.replace(/[^a-zA-Z0-9]/g, ''),
                });
                setNumDoc(value);
                break;
            case "telefone": 
                setFormData({...formData,
                            telefone:value.replace(/[a-zA-Z]/g, ''),
                });
                break;
            default:
                setFormData((prevState) => ({
                    ...prevState,
                    [name]: newValue
                }));
                break;
        }
    };

    const loadOptions = async () => {
        if (listaColaborador[0] === "") return new Promise((resolve, reject) => resolve([]));
        return new Promise((resolve, reject) => {
            resolve(listaColaborador);
        })
    }

    const ColaboradorInputChange = async (value) => {
        const searchResult = await ListarPesquisa(value.replace(/[^a-zA-Z0-9]/g, ''));
                let list = [{}]
                let index = 0;
                searchResult.data.map((data) => {
                    list[index] = {value:data['CPF_COLABORADOR_EVENTUAL'], label:data['NOME_COLABORADOR_EVENTUAL']+" | CPF: "+cpfMask(data['CPF_COLABORADOR_EVENTUAL'])+" | RG: "+data['NUMERO_DOCUMENTO_EVENTUAL']}
                    index++;
                })
                setListaColaborador(list);
    }

    const ColaboraborInputSelect = async (event) => {
        if(!event) return;
        setColaborador(event['value']);
    }

    function DocMask () {
        if(tipoDoc === "1") return "99.999.999-*";
        else return "";
    }


    const handleLocationStateChange = (stateId, stateName) => {
        setFormData({ ...formData, 
                    codUfEndereco: stateId,
                    ufEndereco: stateName });
    };
    
        const handleLocationCityChange = (cityId, cityName) => {
            setFormData({ ...formData, 
                        codCidade: cityId,
                        cidade: cityName });
        };

    const handleBirthStateChange = (stateId, stateName) => {
        setFormData({ ...formData, 
            codUfNascimento: stateId,
            ufNascimento: stateName });
    };

    const handleBirthCityChange = (cityId, cityName) => {
        setFormData({ ...formData, 
            codLocalNascimento: cityId,
            localNascimento: cityName });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true); // Define o estado de carregamento como verdadeiro

        try {
            // await criarEventual(formData); // Envia os dados para a API
            Object.values(formData).forEach((element, key) => {
                if(key !== 23 && key !== 35) {
                    if(element === "") throw new Error ("Erro no preenchimento dos dados | dado incompleto ou inexistente");
                }}
            );;
            
            const eventualResult = await CriarEventual(formData);
            if(eventualResult.status != 200){
                const resultsEventual = eventualResult.split('\n');
                resultsEventual.forEach(element => {
                    if (element !== "") enqueueSnackbar(element, { variant: 'error' });
                })
            }else{
                enqueueSnackbar(eventualResult.data.toString(), { variant: 'success' });
            }

        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
            console.error('Erro ao enviar dados:', error);
        } finally {
            setLoading(false); // Define o estado de carregamento como falso
        }
    };

    const handleCPFSubmit = async (event) => {
        event.preventDefault();
        setLoading(true); // Define o estado de carregamento como verdadeiro
        setCpfEncontrado("");

        try {
            const cpfMasked = colaborador.replace(/[^0-9]/g, '');
            let result;
            if(colaborador.replace(/[^0-9]/g, '').length === 11){
                result = await ListarDados('cpf', cpfMasked); // Envia os dados para a API
            }else if (colaborador.replace(/[^a-zA-Z0-9]/g, '').length === 9){
                result = await ListarDados('rg', colaborador.replace(/[^a-zA-Z0-9]/g, '')); // Envia os dados para a API
            }else{
                result = await ListarDados('nome', colaborador); // Envia os dados para a API
            }
            if(result.data.length <= 0){
                console.log(result);
                new Error("Erro ao buscar CPF");
            }

            const docResult = await BuscarDocumento(result.data[0]['ID_COLABORADOR_EVENTUAL']);

            const dtNasc = new Date(result.data[0]['DATA_DE_NASCIMENTO_COLABORADOR_EVENTUAL']);
            dtNasc.setHours(dtNasc.getHours() + 3);
            setNascimento(dtNasc);

            const dtExp = new Date(docResult.data[0]['DATA_DE_EXPEDISSAO_EVENTUAL']);
            dtExp.setHours(dtExp.getHours() + 3);
            setDataExpedicao(dtExp);
            
            setCep(result.data[0]['CEP_COLABORADOR_EVENTUAL']);
            
            setTipoDoc(docResult.data[0]['COD_TIPO_DOCUMENTO_EVENTUAL'].toString())
            setNumDoc(docResult.data[0]['NUMERO_DOCUMENTO_EVENTUAL'].replace(/[^a-zA-Z0-9]/g, ''))

            setFormData({ ...formData,
                nome: result.data[0]['NOME_COLABORADOR_EVENTUAL'],
                cpf: result.data[0]['CPF_COLABORADOR_EVENTUAL'].replace(/\D/g, ''),
                nomePai: result.data[0]['NOME_DO_PAI_COLABORADOR_EVENTUAL'],
                nomeMae: result.data[0]['NOME_DA_MAE_COLABORADOR_EVENTUAL'],
                cep: result.data[0]['CEP_COLABORADOR_EVENTUAL'].replace(/\D/g, ''),
                complemento: result.data[0]['COMPLEMENTO_COLABORADOR_EVENTUAL'],
                bairro: result.data[0]['BAIRRO_COLABORADOR_EVENTUAL'],
                endereco: result.data[0]['ENDERECO_COLABORADOR_EVENTUAL'],
                numero: result.data[0]['NUMERO_ENDERECO_COLABORADOR_EVENTUAL'],
                telefone: result.data[0]['TELEFONE_COLABORADOR_EVENTUAL'],
                profissao: result.data[0]['PROFISSAO_COLABORADOR_EVENTUAL'],
                empresa: result.data[0]['EMPRESA_COLABORADOR_EVENTUAL'],
                ufNascimento: result.data[0]['SIGLA_ESTADO_NASCIMENTO'],
                codUfNascimento: result.data[0]['COD_UF_LOCAL_DE_NASCIMENTO_COLABORADOR_EVENTUAL'],
                diaNascimento: new Date(dtNasc).getDate(),
                mesNascimento: new Date(dtNasc).getMonth()+1,
                anoNascimento: new Date(dtNasc).getFullYear(),
                diaExpedicao: new Date(dtExp).getDate(),
                mesExpedicao: new Date(dtExp).getMonth()+1,
                anoExpedicao: new Date(dtExp).getFullYear(),
                codLocalNascimento: result.data[0]['COD_LOCAL_DE_NASCIMENTO_COLABORADOR_EVENTUAL'],
                localNascimento: result.data[0]['LOCAL_DE_NASCIMENTO_COLABORADOR_EVENTUAL'],
                ufEndereco: result.data[0]['SIGLA_ESTADO_ENDERECO'],
                codUfEndereco: result.data[0]['COD_UF_CIDADE_COLABORADOR_EVENTUAL'],
                codCidade: result.data[0]['COD_CIDADE_COLABORADOR_EVENTUAL'],
                cidade: result.data[0]['CIDADE_COLABORADOR_EVENTUAL'],
                ctpsNumero: result.data[0]['CPF_COLABORADOR_EVENTUAL'].replace(/\D/g, '').slice(0, 7),
                ctpsSerie: result.data[0]['CPF_COLABORADOR_EVENTUAL'].replace(/\D/g, '').slice(-4),
                tipoDoc: docResult.data[0]['COD_TIPO_DOCUMENTO_EVENTUAL'],
                numDoc: docResult.data[0]['NUMERO_DOCUMENTO_EVENTUAL'],
                orgaoEmissor: docResult.data[0]['ORGAO_EMISSOR_DOCUMENTO_EVENTUAL'],
                codUfOrgaoEmissor: docResult.data[0]['COD_UF_ORGAO_EMISSOR_DOCUMENTO_EVENTUAL'],
                ufOrgaoEmissor: docResult.data[0]['SIGLA_ESTADO']
            });

        } catch (error) {
            console.error('Erro ao enviar dados:', error);
            setCpfEncontrado("Colaborador não encontrado");
        } finally {
            setLoading(false); // Define o estado de carregamento como falso
        }
    };

    return (
        <>
            <Container>
                <div className='eventual'>
                    <div className='container'>
                    <div className={historicoStyle.nav}>
                        <div className={`${historicoStyle.active} ${historicoStyle.navItem}`}>
                        <i className="fa fa-home"></i> Eventual
                        </div>
                        <div className={historicoStyle.navItem} onClick={() => navigate('/eventual/historico')}>
                        <i className="fa fa-history"></i> Histórico
                        </div>
                    </div>
                        <form onSubmit={handleCPFSubmit}>
                            <div className='row mt-5 eventual-custom-border'>
                                <h1 className='p-5'>Buscar Colaborador</h1>
                                    {(cpfEncontrado !== "" ? <span className='eventual-required p-5'>{cpfEncontrado}</span> : "")}
                                <div className='row ml-5 mr-5 eventual-buscar'>
                                    <AsyncSelect 
                                        isClearable
                                        loadOptions={loadOptions}
                                        className='eventual-colaborador-input'
                                        placeholder="Buscar pelo nome, CPF ou RG"
                                        name='colaborador'
                                        styles={{
                                                control: base => ({
                                                  ...base,
                                                  height: 45,
                                                  minHeight: 45
                                                })}}
                                        onInputChange={ColaboradorInputChange}
                                        onChange={ColaboraborInputSelect} />
                                    <button type='submit' className="col-md-1 eventual-search">
                                        <img src={search} alt="" />
                                    </button>
                                </div>
                            </div>
                        </form>
                        <form onSubmit={handleSubmit}>
                            <div className='mt-5 eventual-custom-border p-5 grid'>
                                <div>
                                    <h1 className='eventual-form'>
                                        Nome
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <input placeholder="Digite o nome completo" name="nome" type="text" value={formData.nome} required onChange={handleInputChange} maxLength={255} />
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        CPF
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <input placeholder="Número do CPF" name="cpf" value={cpfMask(formData.cpf)} onChange={handleCPF} type="text" maxLength={24} required />
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Nome do pai
                                    </h1>
                                    <input placeholder="Digite o nome completo" name="nomePai" value={formData.nomePai} type="text" onChange={handleInputChange} maxLength={255} />
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Nome da mãe
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <input placeholder="Digite o nome completo" name="nomeMae" value={formData.nomeMae} type="text" required onChange={handleInputChange} maxLength={255} />
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Tipo de Documento
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <select className='eventual-select-input' id="tipo-doc" name="tipoDoc" value={tipoDoc} required onChange={handleInputChange}>
                                        <option value="">Selecione uma opção</option>
                                        <option value="1">RG</option>
                                        <option value="6">Matrícula</option>
                                        <option value="2">Passaporte</option>
                                        <option value="9">Placa</option>
                                        <option value="3">RNE</option>
                                        <option value="4">SIPE</option>
                                    </select>
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Número do Documento
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <InputMask mask={DocMask()} maskChar=" " placeholder="Digite o número do documento" name="numDoc" value={numDoc} type="text" required onChange={handleInputChange}/>
                                </div>
                                <div className=''>
                                    <h1 className='eventual-form'>
                                        Data de Expedição
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <DatePicker
                                        required
                                        selected={dataExpedicao}
                                        onChange={handleExpDate}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Selecione a data"
                                        className="eventual-date"
                                    />
                                </div>
                                <div>
                                <h1 className='eventual-form'>
                                        Data de Nascimento
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <div>
                                        <DatePicker
                                            required
                                            selected={dataNascimento}
                                            onChange={handleNascDate}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Selecione a data"
                                            className="eventual-date"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Órgão Emissor
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <div className='eventual-local'>
                                        <select className='eventual-select-input' id="state" name="ufOrgaoEmissor" onChange={handleStateChange} value={formData.ufOrgaoEmissor} required>
                                            <option value="">UF</option>
                                            <option value="AC">AC</option>
                                            <option value="AL">AL</option>
                                            <option value="AM">AM</option>
                                            <option value="AP">AP</option>
                                            <option value="BA">BA</option>
                                            <option value="CE">CE</option>
                                            <option value="DF">DF</option>
                                            <option value="ES">ES</option>
                                            <option value="GO">GO</option>
                                            <option value="MA">MA</option>
                                            <option value="MG">MG</option>
                                            <option value="MS">MS</option>
                                            <option value="MT">MT</option>
                                            <option value="PA">PA</option>
                                            <option value="PB">PB</option>
                                            <option value="PE">PE</option>
                                            <option value="PI">PI</option>
                                            <option value="PR">PR</option>
                                            <option value="RJ">RJ</option>
                                            <option value="RN">RN</option>
                                            <option value="RO">RO</option>
                                            <option value="RR">RR</option>
                                            <option value="RS">RS</option>
                                            <option value="SC">SC</option>
                                            <option value="SE">SE</option>
                                            <option value="SP" >SP</option>
                                            <option value="TO">TO</option>
                                        </select>
                                        <input placeholder="Órgão" type="text" name="orgaoEmissor" value={formData.orgaoEmissor} onChange={handleInputChange}  maxLength={8} />
                                    </div>
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Local de Nascimento
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <div className='eventual-local'>
                                        <LocationInput 
                                            selectState={formData.ufNascimento}
                                            selectCity={formData.codLocalNascimento}
                                            onStateChange={handleBirthStateChange}
                                            onCityChange={handleBirthCityChange}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        CTPS
                                    </h1>
                                    <div className='col-span-2 eventual-double-field'>
                                        <input className='col-span-2' placeholder="Número" type="text" name="ctpsNumero" onChange={handleInputChange} value={formData.ctpsNumero}  maxLength={7}/>
                                        <input placeholder="Série" type="text" name="ctpsSerie" onChange={handleInputChange} value={formData.ctpsSerie}  maxLength={4}/>
                                    </div>
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        CEP
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <InputMask value={cep} placeholder='00000-000' mask="99999-999" name="cep" type="text" onChange={handleInputChange} required />
                                </div>
                                <div className='grid'>
                                    <div>
                                    <h1 className='eventual-form'>
                                            Complemento
                                    </h1>
                                    <input type="text" name="complemento" onChange={handleInputChange} value={formData.complemento} placeholder='Complemento' maxLength={255} />
                                    </div>
                                    <div className=''>
                                        <h1 className='eventual-form'>
                                            Bairro
                                            <span className='eventual-required'>*</span>
                                        </h1>
                                        <input type="text" name="bairro" onChange={handleInputChange} value={formData.bairro} placeholder='Bairro' maxLength={255} required/>
                                    </div>
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                            Cidade
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <div className='eventual-local'>
                                    <LocationInput
                                            selectState={formData.ufEndereco}
                                            selectCity={formData.codCidade}
                                            onStateChange={handleLocationStateChange}
                                            onCityChange={handleLocationCityChange}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Endereço
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <div className='eventual-endereco'>
                                    <input type="text" name="endereco" onChange={handleInputChange} value={formData.endereco} placeholder='Logradouro' maxLength={255} required />
                                    <input type="text" name="numero" onChange={handleInputChange} value={formData.numero} placeholder='N°' maxLength={8} required />

                                    </div>
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Telefone
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <input type="text" name="telefone" value={formData.telefone} onChange={handleInputChange} placeholder='Número de telefone ou celular' maxLength={32} required />
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Profissão
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <input type="text" name="profissao" value={formData.profissao} onChange={handleInputChange} placeholder='Emprego' maxLength={128} required />
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Empresa
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <input type="text" name="empresa" value={formData.empresa} onChange={handleInputChange} placeholder='Empresa onde trabalha' maxLength={255} required />
                                </div>
                                <div>
                                    <h1 className='eventual-form'>
                                        Local de Destino
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <input type="text" name="localDestino" value={formData.localDestino} onChange={handleInputChange} placeholder='Local onde o colaborador irá' maxLength={255} required />
                                </div>
                                <div className=''>
                                    <h1 className='eventual-form'>
                                        Período
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <div className='eventual-flex'>
                                        <div className='eventual-teste'>
                                            <div className={`${style.dateRangeLabel} ${style.centeredLabel}`} onClick={() => setShowDatePicker(true)}>
                                                {startDate && endDate
                                                    ? `${startDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' })} - ${endDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' })}`
                                                    : 'Selecione o período'}
                                            </div>
                                            {showDatePicker && (
                                                <div className={style.datePickerWrapper}>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={handleDateChange}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        selectsRange
                                                        inline
                                                        monthsShown={1}
                                                        locale="pt-BR"
                                                        dateFormat="dd/MM/yy"
                                                        placeholderText="Selecione o período"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-span-2'>
                                    <h1 className='eventual-form'>
                                        Motivação
                                        <span className='eventual-required'>*</span>
                                    </h1>
                                    <textarea className='eventual-textarea' name="motivacao" onChange={handleInputChange} maxLength={255} required></textarea>
                                </div>
                                <div className='col-span-2'>
                                    <h1 className='eventual-form'>
                                        Gates
                                    </h1>
                                    <div className='eventual-gate'>
                                        <select className='eventual-select-input' id="gates" name="gates" onChange={handleInputChange} required>
                                            <option value="">Selecione uma opção</option>
                                            <option value="1">Alamoa: Gate 3</option>
                                            <option value="2">Saboó: Gate 4 - Gate 5</option>
                                            <option value="3">Paquetá: Gate 6 - Gate 7 - Gate 8</option>
                                            <option value="4">Outeirinho: Gate 9 - Gate 10 - Gate 11 - Gate 12</option>
                                            <option value="5">Macuco: Gate 14 - Gate 15 - Gate 17 - Gate 18</option>
                                            <option value="6">Travessia para Margem Esquerda: Gate 19</option>
                                            <option value="7">Ponta da Praia: Gate 21</option>
                                            <option value="8">Ilha Barnabé: Gate 27</option>
                                            <option value="9">PFP Ilha Barnabé 04: Gate 28</option>
                                            <option value="12">Margem Esquerda - TEV: Gate TEV</option>
                                            <option value="13">Canal/Barra: Gate MAR</option>
                                        </select>
                                        <select className='eventual-select-input' id="gates2" name="gates2" onChange={handleInputChange}>
                                            <option value="">Selecione uma opção</option>
                                            <option value="1">Alamoa: Gate 3</option>
                                            <option value="2">Saboó: Gate 4 - Gate 5</option>
                                            <option value="3">Paquetá: Gate 6 - Gate 7 - Gate 8</option>
                                            <option value="4">Outeirinho: Gate 9 - Gate 10 - Gate 11 - Gate 12</option>
                                            <option value="5">Macuco: Gate 14 - Gate 15 - Gate 17 - Gate 18</option>
                                            <option value="6">Travessia para Margem Esquerda: Gate 19</option>
                                            <option value="7">Ponta da Praia: Gate 21</option>
                                            <option value="8">Ilha Barnabé: Gate 27</option>
                                            <option value="9">PFP Ilha Barnabé 04: Gate 28</option>
                                            <option value="12">Margem Esquerda - TEV: Gate TEV</option>
                                            <option value="13">Canal/Barra: Gate MAR</option>
                                        </select>
                                    </div>
                                </div>
                                
                                <button type="submit" className="col-span-2 eventual-submit" disabled={loading}>
                                    {loading && <div className="spinner"></div>}
                                    {loading ? 'Enviando...' : 'Enviar'}
                                    <span className="button-text">
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default Eventual;