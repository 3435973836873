import React, { useState, useEffect } from "react";
import Container from "../../../components/Container";
import style from "./HistoricoEventual.module.css";
import SubmitButton from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import modalStyle from './Modal.module.css';
import ViewTable from "../../../components/ViewTable/view-table";
import { ListarHistoricoEventual } from "../../../services/api-eventual";

const HistoricoEventual = () => {

  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [tableData, setTableData] = useState([""])


  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent("");
  };

  const BuscarHistoricoEventual = (callback) => {
    ListarHistoricoEventual((err, result) => {
      const resultHistorico = result.data;
      let data = [];

      data = resultHistorico.map((element) => Object.values(element));
      callback("",data);
    });
  }

  useEffect(() => {
    BuscarHistoricoEventual((err, result) => {
      setTableData(result);
    });
    }, []);

  return (
    <>
      <Container>
        <div className='eventual'>
          <div className='container'>
              <div className={style.nav}>
                <div className={style.navItem} onClick={() => navigate('/eventual/cadastro')}>
                  <i className="fa fa-home"></i> Eventual
                </div>
                <div className={`${style.active} ${style.navItem}`}>
                  <i className="fa fa-history"></i> Histórico
                </div>
              </div>
            <div className={"mt-5 "+style.content}>
              <ViewTable
                columnNames={["ID","Nome", "CPF", "Justificativa", "Inicio Periodo", "Fim Periodo", "Data de Emissao", "Protocolo", "Status", "Motivador"]}
                rowValues={tableData}
              />

              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Motivação Conteúdo"
                className={modalStyle.modal}
                overlayClassName={modalStyle.overlay}
              >
                <div className={modalStyle.nav}>
                  <div className={modalStyle.active}>
                    <i className="fa fa-exclamation-circle"></i> CPFs Motivados!
                  </div>
                </div>
                <div className={modalStyle.columns}>
                  {modalContent.split(';').map((item, index) => (
                    <div key={index} className={modalStyle.columnItem}>{item}</div>
                    ))
                    }
                </div>
                <SubmitButton text="Fechar" onClick={closeModal} />
              </Modal>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default HistoricoEventual
