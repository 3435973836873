import React, { useEffect, useState } from 'react';
import { BuscarCidades } from '../../services/api-sspp-cidades.js';

const LocationInput = ({ selectState, selectCity, onStateChange, onCityChange }) => {
    const [states, setStates] = useState([
        { id: 1, sigla: 'AC' },
        { id: 2, sigla: 'AL' },
        { id: 3, sigla: 'AM' },
        { id: 4, sigla: 'AP' },
        { id: 5, sigla: 'BA' },
        { id: 6, sigla: 'CE' },
        { id: 7, sigla: 'DF' },
        { id: 8, sigla: 'ES' },
        { id: 9, sigla: 'GO' },
        { id: 10, sigla: 'MA' },
        { id: 11, sigla: 'MG' },
        { id: 12, sigla: 'MS' },
        { id: 13, sigla: 'MT' },,
        { id: 14, sigla: 'PA' },
        { id: 15, sigla: 'PB' },
        { id: 16, sigla: 'PE' },
        { id: 17, sigla: 'PI' },
        { id: 18, sigla: 'PR' },
        { id: 19, sigla: 'RJ' },
        { id: 20, sigla: 'RN' },
        { id: 21, sigla: 'RO' },
        { id: 22, sigla: 'RR' },
        { id: 23, sigla: 'RS' },
        { id: 24, sigla: 'SC' },
        { id: 25, sigla: 'SE' },
        { id: 26, sigla: 'SP' },
        { id: 27, sigla: 'TO' }
    ]);
    const [cities, setCities] = useState([]);
    const [selectedState, setSelectedState] = useState(selectState);
    const [selectedCity, setSelectedCity] = useState(selectCity);

    useEffect(() => {
        setSelectedState(selectState);
        setSelectedCity(selectCity);
    }, [selectState, selectCity]);

    useEffect(() => {
        const loadCities = async () => {
            if (selectedState) {
                const citiesData = await BuscarCidades(selectedState);
                setCities(citiesData);
            }
        };

        loadCities();
    }, [selectedState]);

    const handleStateChange = async (event) => {
        const stateName = event.target.value;
        const stateId = event.target.selectedIndex;
        // console.log("Event: ", event.target.selectedIndex);
        setSelectedState(stateName);
        setSelectedCity(""); // Clear selected city when state changes
        // setCities([]);
        if (stateName) {
            const citiesData = await BuscarCidades(stateName);
            setCities(citiesData);
        }
        onStateChange(stateId, stateName);
    };

    const handleCityChange = (event) => {
        const cityId = event.target.value;
        const cityName = event.target.options[event.target.selectedIndex].text;
        console.log("Event: ", event.target.options[event.target.selectedIndex].text, " | ", event.target.value);
        setSelectedCity(cityId);
        onCityChange(cityId, cityName);
    };

    return (
        <div className='col-span-2 eventual-local'>
            <select className='eventual-select-input' id="state" name="state" onChange={handleStateChange} value={selectedState} required>
                <option value="">UF</option>
                {states.map((state) => (
                    <option key={state.id} value={state.sigla}>{state.sigla}</option>
                ))}
            </select>
            <select className='eventual-select-input' id="city" name="city" onChange={handleCityChange} value={selectedCity} required>
                <option value="">Selecione uma cidade</option>
                {cities.map((city) => (
                    <option key={city.id} value={city.id}>{city.nome}</option>
                ))}
            </select>
        </div>
    );
};

export default LocationInput;
