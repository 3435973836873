const axios = require('axios');
const apiUrl = '/api/eventual/cidades';

async function BuscarCidades (UF) {
	try {
		const response = await axios.get(apiUrl, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            params: {
              state:UF
            }
        });
		if (response.status !== 200) {
			throw new Error('Erro ao buscar cidades');
		}

		return response.data
    } catch (error) {
      console.log('Erro: ', error);
    }
}

export { BuscarCidades };
