import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './styles.css';

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [empresa, setEmpresa] = useState(null);
  const [socket, setSocket] = useState(null);


  useEffect(() => {
    // Função para buscar o valor da empresa
    const fetchEmpresa = async () => {
      try {
        const response = await axios.get('/api/login/token/empresa', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setEmpresa(response.data['empresa']);
        console.log(response.data['empresa']);
      } catch (error) {
        console.error('Erro ao buscar a empresa:', error);
      }
    };

    fetchEmpresa();
  }, []);

  const menuItems = [
    { route: "/dashboard", icon: "home", text: "Dashboard", activeProp: "dashboard", allowedIds: [2, 4] },
    { route: "/motivacao/Motivados", icon: "person", text: "Motivação", activeProp: "motivados", allowedIds: [2, 4] },
    { route: "/eventual/cadastro", icon: "confirmation_number", text: "Eventuais", activeProp: "eventuais", allowedIds: [2] },
    { route: "/admin", icon: "folder", text: "Painel Administrativo", activeProp: "usuarios", allowedIds: [2] },
    { route: "/user", icon: "settings", text: "Configurações", activeProp: "configuracoes", allowedIds: [2] },
    { route: "/solicitacoes", icon: "check", text: "Solicitações", activeProp: "", allowedIds: [2] },
    { route: "/tour", icon: "explore", text: "Tour Virtual", activeProp: "", allowedIds: [2, 4] },
  ];

  const filteredMenuItems = empresa ? menuItems.filter(item => item.allowedIds.includes(empresa)) : [];

  const handleNavigation = (route) => {
    setIsActive(false);
    navigate(route);
  };

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const logOut = () => {
    localStorage.clear();
    window.location.href = 'https://orion-op.com.br/';
  }

  const handleMenuHover = () => {
    setIsActive(true);
  };

  const handleMenuLeave = () => {
    setIsActive(false);
  };

  return (
    <div id="menu" onMouseEnter={handleMenuHover} onMouseLeave={handleMenuLeave}>
      <div className={`menu2`}>
        <nav className={`menu-horizontal ${isActive ? 'active' : ''} ${isActive ? '' : 'menu-hidden'} ${window.innerWidth <= 768 ? 'mobile' : ''}`}>
          <ul className="itens-menu">
            <li onClick={toggleMenu}>
              <div className="nav_bar">
                <span className="material-icons">menu</span>
              </div>
            </li>
            {filteredMenuItems.map((item, index) => (
              <li 
                key={item.route} 
                onClick={() => handleNavigation(item.route)} 
                className={`${location.pathname === item.route ? 'ativo' : ''} ${index === 0 ? 'first-tab' : ''}`}
              >
                <div className="nav_bar">
                  <span className="material-icons">{item.icon}</span>
                  <span className="menu_text">{item.text}</span>
                </div>
              </li>
            ))}
            <li onClick={logOut}>
              <div className="nav_bar">
                <span className="material-icons">exit_to_app</span>
                <span className="menu_text">SAIR</span>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
