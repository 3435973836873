import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Fragment } from "react";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import useAuth from "../hooks/useAuth";
import Motivados from "../pages/motivacao/Motivados";
import CadastroMotivado from "../pages/motivacao/CadastroMotivado";
import HistoricoMotivacao from "../pages/motivacao/HistoricoMotivacao";
import Eventual from "../pages/Eventual/CadastroEventual/eventual.jsx";
import HistoricoEventual from "../pages/Eventual/HistoricoEventual/HistoricoEventual.jsx";
import UserSettings from "../pages/usuario/user.jsx";
import Admin from "../pages/admin/admin.jsx";
import VirtualTour from "../pages/virtual-tour/virtual-tour.jsx";
import Aprovacao from "../pages/aprovacao/aprovacao.jsx";
import Layout from "../components/Layout/layout.jsx";

const PrivateRoute = ({ element: Element }) => {
  let { signed } = useAuth();

  if (signed === null) {
    return <div>Loading...</div>;
  }

  return signed ? <Element /> : window.location.href = 'https://orion-op.com.br/?lastService=2';
};

const RoutesApp = () => {
  return (
    <BrowserRouter>
      <Fragment>
        <Routes>
          <Route path="/login" element={<Login />} />
          '<Route path="/login/password/:token" element={<Login />} />
          <Route element={<PrivateRoute element={Layout} />}>
            <Route path="/dashboard/:id" element={<Dashboard />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/eventual/cadastro" element={<Eventual />} />
            <Route path="/eventual/historico" element={<HistoricoEventual />} />
            <Route path="/User" element={<UserSettings />} />
            <Route path="/motivacao/motivados" element={<Motivados />} />
            <Route path="/motivacao/historicomotivacao" element={<HistoricoMotivacao />} />
            <Route path="/motivacao/cadastro" element={<CadastroMotivado />} />
            <Route path="/tour" element={<VirtualTour />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/solicitacoes" element={<Aprovacao />} />
            <Route path="*" element={<Dashboard />} />
          </Route>
        </Routes>
      </Fragment>
    </BrowserRouter>
  );
};

export default RoutesApp;
