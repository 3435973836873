import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [token, setToken] = useState("");
  
  useEffect(() => {
    setToken(searchParams.get('token'));
    const token = searchParams.get('token');
    localStorage.setItem("token", token);
    if(!token){
      window.location.href = 'https://orion-op.com.br/login';
    }else{
      navigate("/dashboard");
    }
  },[])
  return <h1>{token}</h1>
};

export default Login;
