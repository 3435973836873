import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import modalStyle from './Modal.module.css'
import Modal from 'react-modal';
import { useState } from "react";



const ViewTable = (params) => {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const columnLabels = params['columnNames'];
    const rowValues = params['rowValues'];

    const closeModal = () => {
        setModalIsOpen(false);
        setModalContent("");
      };

    const handleShowContent = (params, event, details) => {
    setModalContent(params.row);
    setModalIsOpen(true);
    };


    const ConvertColumnNames = (names) => {
        let columnLabels = []
        names.forEach(element => {
            columnLabels.push(camelize(element));
        });
        return columnLabels;
    }
    
    const CreateColumns = (columnLabels) => {
        const columnNames = ConvertColumnNames(columnLabels);
        let columns = [];
        let index = 0;
        columns.push(columnNames.forEach((element, index) => {
            columns.push({
                field:element,
                headerName:columnLabels[index],
                editable:false,
                flex: (index < 0) ? 0 :1
            })
        }));
        columns.pop();
        return columns;
    }

    const CreateRows = (columnLabels) => {
        const columnNames = ConvertColumnNames(columnLabels);
        let rows = [];
        
        if(rowValues){
            rowValues.forEach(rowElement => {
                let row = {};
                columnNames.forEach((columnElement, columnIndex) => {
                    row = {...row, [columnElement]:[rowElement[columnIndex]]}
                })
                rows.push(row);
            });
        }
        return rows;
    }
    const row = CreateRows(columnLabels);
    const column = CreateColumns(columnLabels);
    
    //Convert string input into camelCase
    function camelize(input) {
        const str = input.toLowerCase();
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
      }

  return (
    <>
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
                sx={{
                  '&, [class^=MuiDataGrid]': { border: 'none' },
                  // disable cell selection style
                  '.MuiDataGrid-cell:focus': {
                    outline: 0
                  },
                  // pointer cursor on ALL rows
                  '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer'
                  }
                }}
                rows={row}
                columns={column}
                initialState={{
                    pagination: {
                      pageIndex: 0,
                      pageSize: 20,
                    },
                  }}
                  onRowClick={handleShowContent}
            />
        </Box>
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Eventual Conteúdo"
            className={modalStyle.modal2}
            overlayClassName={modalStyle.overlay}
          >
              <div className={modalStyle.nav}>
                <div className={modalStyle.active}>
                  Dados Eventual
                </div>
              </div>
              <div className={"grid "+modalStyle.grid}>
              {
               Object.entries(modalContent).map((element, key) => {
                return <div className="m-0">
                        <label>{columnLabels[key]}</label>
                        <input className={modalStyle.modalInput} name={element[0]} value={element[1]} type="text" readOnly />
                      </div>
               })
              }
              </div>
              <div className={modalStyle.modalButtons}>
              <button onClick={() => closeModal()} className={`${modalStyle.modalButton} ${modalStyle.modalSave}`}>Fechar</button>
              </div>
          </Modal>
    </>
  );
};

export default ViewTable;
